export const getInitials = (fullName) => {
  if (fullName) {
    const arr = fullName.trim().split(' ');
    if (arr.length === 1) {
      return arr[0]?.substring(0, 1);
    }
    return arr[0]?.substring(0, 1) + arr[1]?.substring(0, 1);
  }
};

export const pxToRem = (px) => {
  return px / getComputedStyle(document.getRootNode()).fontSize;
};

export const reverseDate = (date, separator) => {
  if (!date) return null;
  return date.split(separator).reverse().join(separator);
};

export const formatDate = (dateString, separator) => {
  if (!dateString) {
    return null;
  }
  const parts = dateString.split(separator);
  const day = parts[0];
  const month = getMonthName(parts[1]);
  const year = parts[2];

  return `${day} ${month} ${year}`;
};

const getMonthName = (month) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return months[parseInt(month) - 1];
};

export const getDaysDifference = (date_obj1, date_obj2) => {
  let Difference_In_Time = date_obj2?.getTime() - date_obj1?.getTime();

  // Calculating the no. of days between two dates
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
  return Difference_In_Days;
};

export const currencyFormatterWithoutSymbol = (number, formatToM, extraOptions) => {
  // const number = 123456.789;
  if (number === 0) {
    return '0';
  }
  if (!number) return;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    ...extraOptions,
  });
  if (Math.abs(number) >= 1000000 && formatToM) {
    const millionNumber = number / 1000000; // Convert to millions
    const res = formatter
      ? formatter.format(millionNumber).replace(/\.?0+$/, '')
      : formatter.format(millionNumber);
    return res + 'M'; // Add 'M' for millions notation
  }
  const res = formatter.format(number);
  return res;
};

export const currencyFormatter = (number, currency, extraOptions, formatToM, format) => {
  if (currency === null || !number) return;
  let formatter;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
  });
  if (Math.abs(number) >= 1000000 && formatToM) {
    const millionNumber = number / 1000000; // Convert to millions
    const res = format
      ? formatter.format(millionNumber).replace(/\.?0+$/, '')
      : formatter.format(millionNumber);
    return res + 'M'; // Add 'M' for millions notation
  }
  const res = format ? formatter.format(number).replace(/\.?0+$/, '') : formatter.format(number);
  return res;
};

export const conditionalEntityCodeQuery = (obligation) => {
  const isGroupObligation = obligation.org_heirarchy_code === obligation.obligation_group_id;
  return isGroupObligation
    ? ''
    : `&entitycode=${encodeURIComponent(obligation.org_heirarchy_code)}`;
};

export const conditionalEntityCodeDefaultQuery = (obligation) => {
  const isGroupObligation = obligation.org_heirarchy_code === obligation.obligation_group_id;
  return isGroupObligation
    ? ''
    : `entitycode__eq=${encodeURIComponent(obligation.org_heirarchy_code)}`;
};

export const nonce = (len) => {
  var text = '';
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < len; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const forceUnicodeEncoding = (string) => {
  return decodeURIComponent(encodeURIComponent(string));
};

export const configureVatGst = (countryCode) => {
  const pre_configured_country_codes = ['AU', 'BE', 'NZ', 'PH'];
  if (pre_configured_country_codes.includes(countryCode)) return 'GST';
  else return 'VAT';
};

export const objectHasKey = (obj, key) => {
  if (!obj) return false;
  if (Object.keys(obj).length === 0) return false;
  return Object.hasOwn(obj, key);
};

export const lookerEmbedDomain = new URL(window.location).origin;
export const getCurrentRelativePath = (removeQuery) => {
  const urlObject = new URL(window.location);
  if (removeQuery) {
    urlObject.searchParams.delete(removeQuery);
  }
  const relativePath = urlObject.pathname + urlObject.search;
  return encodeURIComponent(relativePath);
};

export const preventReloadOnInputEnter = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault(); // Prevent the default Enter key action
  }
};

export const lookerEntityCodeList = (isGroupSelected, sisterObligations, selectedObligation) =>
  isGroupSelected && sisterObligations.length > 1
    ? sisterObligations
        .filter((obg) => obg.differentiator === 'member')
        .map((obg) => obg.org_heirarchy_code)
        .join(',')
    : selectedObligation?.org_heirarchy_code;

export const nullableInvoiceNumber = (txInvNo, erpDocNO) => txInvNo ?? erpDocNO;

export const getWebDataSetFromUrl = ()=>{
  const url = window.location.href;  
  const regex = /https:\/\/(.+?)\./;  
  const result = url.match(regex);  
  const tenant = result[1];  
  return tenant
}