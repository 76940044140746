import { GlobalDashboards } from './GlobalDashboards/index';
import { HomePage } from './HomePage/index';
import { Obligations } from './Obligations/index';
import { ErrorPage } from '../components/common/ErrorPage/ErrorPage';
import { IndividualObligation } from './Obligations/IndividualObligation';
import { Buttons } from '../components/common/Buttons';
import { UserSettings } from './UserSettings/index';
import { ListTransaction } from './Obligations/Transactions/ListTransaction';
import { OrphanTable } from './Obligations/OrphanTable';
import { ExceptionTransactionTable } from './Obligations/Exceptions/ExceptionTransactionTable';
import { AnalyticsTransactionDashboard } from './Obligations/Analytics/Transaction Analytics/AnalyticsTransactionDashboard';
import { ListSupplyTransaction } from './Obligations/Transactions/ListSupplyTransaction';
import { ListPurchaseTransaction } from './Obligations/Transactions/ListPurchaseTransaction';
import TransactionDetail from './Obligations/Exceptions/TransactionDetail';
import { MultitenancyTest } from './MultitenancyTest';

export const pagesData = [
  {
    path: '/home',
    element: <HomePage />,
    title: 'home',
    nested: false,
  },
  {
    path: '/user-settings',
    element: <UserSettings />,
    title: 'user-settings',
    nested: false,
  },
  {
    path: '/global-dashboard',
    element: <GlobalDashboards />,
    title: 'global-dashboard',
    nested: false,
  },
  {
    path: '/obligations',
    element: <Obligations />,
    title: 'obligations',
    nested: true,
    nestedRoutes: [
      {
        path: ':id/:member/:tab?/:subTab?/:childTab?/:detailTab?/:nestedTab?',
        element: <IndividualObligation />,
        title: 'individual-obligation',
      },
      {
        path: ':id/:member/all-transactions',
        element: <ListTransaction />,
        title: 'all-transactions',
      },
      {
        path: ':id/:member/individual-transaction',
        element: <TransactionDetail />,
        title: 'individual-transaction',
      },
      {
        path: ':id/:member/supply-transactions',
        element: <ListSupplyTransaction />,
        title: 'supply-transactions',
      },
      {
        path: ':id/:member/purchase-transactions',
        element: <ListPurchaseTransaction />,
        title: 'purchase-transactions',
      },
      {
        path: ':id/transaction-dashboard/:query?',
        element: (
          <OrphanTable>
            <AnalyticsTransactionDashboard />
          </OrphanTable>
        ),
        title: 'transaction-dashboard',
      },
      {
        path: ':id/exception-dashboard/:query?',
        element: (
          <OrphanTable>
            <ExceptionTransactionTable />
          </OrphanTable>
        ),
        title: 'exception-dashboard',
      },
    ],
  },
  {
    path: '/looker-test',
    element: <MultitenancyTest />,
    title: 'error',
    nested: false,
  },
  {
    path: '*',
    element: <ErrorPage />,
    title: 'error',
    nested: false,
  },
];
